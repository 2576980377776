/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bell-slash-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.164 14H15c-1.5-1-2-5.902-2-7q0-.396-.06-.776zm6.288-10.617A5 5 0 008.995 2.1a1 1 0 10-1.99 0A5 5 0 003 7c0 .898-.335 4.342-1.278 6.113zM10 15a2 2 0 11-4 0zm-9.375.625a.53.53 0 00.75.75l14.75-14.75a.53.53 0 00-.75-.75z"/>',
    },
});
